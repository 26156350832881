body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.cp_embed_wrapper, .codepen-box {
  width: 100%;
}

.codepen-box{
  opacity: 0;
  animation: codepen 1s 5.5s forwards;
}

.coding {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  width: 25ch;
  overflow: hidden;
  white-space: nowrap;
  border-right: .1em solid #fff;
  animation: typing 5s steps(25, end), blink .5s 5.5s step-end infinite alternate;
}

/*光标动画*/
@keyframes blink {
	50% {
    border-color: transparent;
  }
}

/*文本动画：从宽度为0开始*/
@keyframes typing {
	from {
    width: 0;
  }
}

/*codepen show*/
@keyframes codepen {
  from{
    opacity: 0;
  }
  
  to {
    opacity: 1;
  }
}
